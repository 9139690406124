.privacyContainer {
    ol>li {
        font-weight: bold;
    }
    div {
        margin-left: 20px;
    }
}

@media (max-width: 991px) {
    .privacyContainer {
        div {
            margin-left: 0px;
        }
    }
}
.container-settings {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
}

.settings {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    background-color: white;

    .setting-menu {
        width: auto;
        background-color: #f4f5f8;
        padding: 20px 20px 0 20px;
        font-family: "Poppins";
        background-color: white;

        h5 {
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: #072136;
            margin: 0;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                margin-top: 20px;
                padding: 10px;
                border-radius: 10px;
                // margin-left: 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                color: #415473;
                cursor: pointer;

                svg {
                    width: 50px;
                }

                &.active {
                    background-color: #F4F5F8;
                    // color: var(--bs-orange);

                    // &::after {
                    //     content: "";
                    //     display: inline-block;
                    //     width: 0;
                    //     height: 0;
                    //     margin-left: 5px;
                    //     margin-bottom: 1px;
                    //     border-top: 5px solid transparent;
                    //     border-left: 10px solid var(--bs-orange);
                    //     border-bottom: 5px solid transparent;
                    // }
                }
            }
        }
    }

    .setting-content {
        flex-grow: 1;
        padding: 100px;
        border-radius: 30px;
        box-shadow: 0px 0px 50px 0px rgba(65, 85, 116, 0.08);
        width: 76%;

        .subtitle {
            color: var(--periwinkle, #5C89D1);
            /* p-large/bold */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .title {
            color: var(--Dark-Navy, #072136);
            /* h2/desktop */
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 37px;
            /* 132.143% */
        }

        .tagline {
            color: rgba(7, 33, 54, 0.5);
            border-bottom: 1px solid #dadbe2;
        }
    }

    .setting-right {
        width: 12%;
    }

    .save-button {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 700;
    }

    .cancel-button {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 700;
    }
}

.billing-setting {
    .billing-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #072136;
        margin: 0 0 40px 0;
    }

    .Input__label {
        color: #072136;
    }
}

.users-setting {
    max-width: 500px;

    .user-card {
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 20px;
        margin-bottom: 30px;
        cursor: pointer;
        transition-duration: 0.3s;

        &.active {
            background: #ffffff;
            box-shadow: 0px 4px 28px rgba(218, 219, 226, 0.6);
        }

        .user-avatar {
            display: inline-block;
            width: 63px;
            height: 63px;
            border-radius: 50%;
            background: #72c4ff;
            font-weight: 700;
            font-size: 22px;
            line-height: 63px;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
        }

        .user-card-content {
            display: flex;
            flex-direction: column;

            .label-wrap {
                display: flex;
                gap: 5px;
                min-height: 30px;
                width: 100%;
                margin-bottom: 5px;
            }

            .user-label {
                display: inline-block;
                padding: 0 10px;
                background: #5eb5ca;
                border-radius: 50px;
                color: #fff;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
            }

            .user-status {
                display: inline-block;
                padding: 0 10px;
                border: 1px solid #5eb5ca;
                border-radius: 50px;
                color: #5eb5ca;
                font-weight: 400;
                font-size: 14px;
                line-height: 28px;
            }

            .user-edit {
                margin-left: 22px;
                cursor: pointer;
            }

            .user-name {
                margin: 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #072136;
            }

            .user-email {
                font-size: 14px;
                line-height: 21px;
                color: #415473;
                opacity: 0.5;
            }
        }
    }

    .user-card:hover {
        background: #ffffff;
        box-shadow: 0px 4px 28px rgba(218, 219, 226, 0.6);
    }

    .user-card-add {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 119px;
        background: #ecedf0;
        font-size: 16px;
        line-height: 24px;
        color: #566575;
        cursor: pointer;

        .add-icon {
            margin-right: 30px;
        }
    }
}

.dashboard-setting {
    .pb-40 {
        padding-bottom: 40px;
    }

    .pb-90 {
        padding-bottom: 90px;
    }

    .subtitle2 {
        color: #415473;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
    }
}

.account-setting {
    .Input__input[readonly] {
        border: none !important;
    }

    .Input__label {
        color: #072136;
    }

    .italicText {
        font-style: italic;
    }

    .btn-password {
        margin-top: -25px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #5C89D1;
        flex: none;
        order: 1;
        flex-grow: 0;
        cursor: pointer;
    }

    .btn-delete {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #f37845;
        flex: none;
        order: 1;
        flex-grow: 0;
        cursor: pointer;
    }

    .link-forgot {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #f37845;
    }
}

.rfp-settings {
    .rfp-settings-category {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .category-heading {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #072136;
        }

        .setting-list-container {
            padding: 15px;

            p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #415473;
                opacity: 0.5;
            }
        }
    }

    .settings-edit-info-button {
        background-color: white;
        color: #415473;
        border: 3px solid #415473;
    }

    .filter-collapse {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: #415473;
        margin-top: 40px;
        text-align: center;
        cursor: pointer;
    }
}

.rfp-settings-edit-info {
    .rfp-settings-edit-info-category {
        display: flex;
        flex-direction: column;
        width: 70%;
        border-bottom: 1px solid #dadbe2;
        padding-bottom: 30px;
        margin-bottom: 40px;

        .category-heading {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #072136;
        }

        .clear-all-text {
            text-decoration: underline;
            color: #9497ab;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 10px;
        }

        .searchbar-container {
            position: relative;
            width: 100%;
            height: 46px;
            background: #ffffff;
            border: 1px solid #dadbe2;
            border-radius: 70px;

            input {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                padding: 0 20px;
                font-size: 16px;
                line-height: 24px;
                color: #415473;
                outline: 0;
            }

            svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: #415473;
            }
        }

        .list-container {
            list-style: none;
            padding: 0;

            li {
                margin-top: 20px;
            }

            margin-bottom: 10px;
        }

        .show-all {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-decoration-line: underline;
            color: #9497ab;
        }
    }

    .button-container {
        display: flex;
        margin-top: 60px;

        .edit-button {
            background-color: white;

            color: #415473;
            border: 2px solid #415473;
        }
    }
}

.notification-toggle-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust margin as needed */
  }
  
  .notification-toggle-text {
    margin-right: 30px; /* Adjust margin as needed */
  }

.btn--outline--blue {
    border-color: #5eb5ca;
    font-weight: 700;
    color: #000;

    &:hover {
        background-color: #5eb5ca;
        color: #fff;
    }
}

.btn--outline--red {
    border-color: #ec5757;
    font-weight: 700;
    color: #000;

    &:hover {
        background-color: #ec5757;
        color: #fff;
    }
}

.delete-content {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.change-password {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

@media (max-width: 991px) {
    .settings {
        flex-direction: column;

        .setting-menu {
            width: 100%;
            padding: 50px 0 0 0;
            text-align: center;

            ul {
                display: flex;
                justify-content: center;
                gap: 30px;
                margin-top: 10px;
                margin-bottom: 50px;

                li {
                    margin-top: 0;
                    margin-left: 0;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    color: #415473;
                    cursor: pointer;

                    &.active {
                        color: var(--bs-orange);

                        &::after {
                            content: "";
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 5px;
                            margin-bottom: 1px;
                            border-top: 5px solid transparent;
                            border-left: 10px solid var(--bs-orange);
                            border-bottom: 5px solid transparent;
                        }
                    }
                }
            }
        }

        .setting-content {
            padding: 50px 30px;
            width: 100%;

            .subtitle {
                font-weight: 700;
                font-size: 22px;
                line-height: 28px;
                color: var(--bs-orange);
                margin: 0;
            }

            .title {
                font-weight: 700;
                font-size: 38px;
                line-height: 60px;
                margin: 0;
            }

            .tagline {
                color: rgba(7, 33, 54, 0.5);
                border-bottom: 1px solid #dadbe2;
            }
        }

        .setting-right {
            display: none;
        }
    }

    .rfp-settings-edit-info {
        .rfp-settings-edit-info-category {
            width: 100%;
        }
    }

    .users-setting {
        .user-card {
            padding: 5px;

            .user-avatar {
                width: 50px;
                height: 50px;
                font-size: 20px;
                line-height: 50px;
            }

            .user-card-content {
                .label-wrap {
                    min-height: 24px;
                }

                .user-status {
                    padding: 0 10px;
                    font-size: 12px;
                    line-height: 22px;
                }

                .user-label {
                    padding: 0 10px;
                    font-size: 12px;
                    line-height: 22px;
                }
            }
        }
    }
}
.MultiSelectDropdown {
    width: 100%;

    .naics-wrapper {
        flex-wrap: wrap;
        padding-top: 20px;
        .naics-item {
            align-items: center;
            background-color: #F4F5F8;
            padding: 10px 15px 10px 15px;
            margin-right: 10px;
            border-radius: 10px;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            color: #415473;
            cursor: pointer;
        }
    }

    .MultiSelectDropdown__input {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: #415473;
        font-size: 16px;
        line-height: 24px;
        // border: 1px solid #DADBE2;
        border-radius: 10px;
        padding: 15px 0px;
        cursor: pointer;

        .filter-title {
            min-width: 198px;
            height: 27px;
            flex-grow: 1;
            margin: 0;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            color: #415473;
        }
    }

    .collapse-menu {
        display: none;
        width: 100%;
        // background: rgba(218, 219, 226, 0.2);
        // border: 1px solid #9497AB;
        border-top: none;
        border-radius: 0px 0px 10px 10px;
        // padding: 10px;

        .clearSelect {
            width: 126px;
            height: 21px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            margin-right: 10px;
            text-align: right;
            text-decoration-line: underline;
            color: #9497ab;
        }

        .dropdown-search {
            position: relative;
            width: auto;
            background: #ffffff;
            border: 1px solid #dadbe2;
            border-radius: 70px;
            text-align: left;
            margin: 10px 0;

            input {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                padding: 11px 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #415473;
                outline: 0;
            }

            svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: #415473;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            max-height: 300px;
            overflow-y: auto;

            li {
                padding: 10px 10px 10px 0;
                display: flex;
                justify-content: space-between;
                color: #9497ab;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #dadbe2;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #415473;
                border-radius: 3px;
            }
        }
    }

    &.active {
        .MultiSelectDropdown__input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-color: #9497ab;
        }

        .collapse-menu {
            display: block;
        }
    }
}
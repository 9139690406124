.rfp__detail {
    padding: 35px 40px 75px;
    background: #ffffff;
    border-radius: 30px;

    .rfp__detail__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .rfp__detail__header__title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #415473;
            cursor: pointer;

            svg {
                margin-right: 6px;
            }
        }
    }

    .rfp__detail__body {
        display: flex;
        gap: 16px;

        .heading-title-2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15em;
            // text-transform: uppercase;
            color: #072136;
        }

        .heading-title-3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            color: #415473;
            margin: 30px 0 10px;
        }

        .toggle-icon {
            cursor: pointer;
        }

        &__aside {
            width: 286px;
            border: 1px solid #dadbe2;
            border-radius: 20px;
            padding: 30px 25px;
            flex-shrink: 0;
            height: fit-content;

            &__predict{
                background-color: #F4F5F8;
                width: 146px;
                height: 27px;
                padding: 5px;
                margin-top: 30px;
                margin-bottom: -15px;
                border-radius: 10px;
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 400;
                color: #415473;
                line-height: 17px;
                cursor: pointer;
            }

            &__title {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #9497ab;
                margin-bottom: 20px;
                text-transform: uppercase;
                letter-spacing: 0.15em;
            }

            &__item {
                display: flex;
                font-size: 16px;
                line-height: 24px;
                padding: 25px 0 30px;
                color: #415473;

                svg {
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                }

                b {
                    display: block;
                }
            }

            &__query {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #9497ab;
                margin-bottom: 20px;
            }

            &__buttons {
                display: flex;
                justify-content: space-around;
                padding: 0 30px;

                span {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    text-decoration-line: underline;
                    color: #415473;
                    cursor: pointer;
                }
            }

            &__badge-container {
                display: flex;

                .badges {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .rfp__detail__body__content {
            flex-grow: 1;
            margin: 0 46px;
            word-break: break-word;

            .rfp__detail__body__content__header {
                display: flex;
                gap: 20px;
                width: 100%;
                height: 50px;
                justify-content: space-between;
                align-items: center;
                color: #dadbe2;
                margin-bottom: 24px;

                .post-box-wrapper {
                    display: flex;
                    align-items: center;
                }

                .post-box__applied {
                    justify-content: center;
                    align-items: center;
                    background-color: #f37845;
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1;
                    color: white;
                    padding: 13px;
                    // margin-top: 10px;
    
                    svg {
                        margin-top: -3px;
                        margin-left: 10px;
                        height: 12px;
                        // width: 20px;
                    }
                }

                .post-box__favorite {
                    justify-content: center;
                    align-items: center;
                    background-color: #5c89d1;
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1;
                    color: white;
                    padding: 13px;
                    // margin-top: 10px;
    
                    svg {
                        margin-top: -3px;
                        margin-left: 10px;
                        height: 12px;
                        // width: 20px;
                    }
                }
    
                .post-box__hide {
                    justify-content: center;
                    align-items: center;
                    background-color: #415473;
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1;
                    color: white;
                    padding: 13px;
                    // margin-top: 10px;
    
                    svg {
                        margin-top: -3px;
                        margin-left: 10px;
                        height: 12px;
                        // width: 20px;
                    }
                }

                .rfp__detail__body__content__header_right {
                    display: flex;
                    gap: 20px;
                    justify-content: flex-end;
                    align-items: center;
                    color: #dadbe2;

                    svg {
                        color: #dadbe2;
                        margin-left: 20px;

                        &.active {
                            color: #5C89D1;
                        }
                    }
                }
            }

            .rfp__detail__body__content__title {
                font-weight: 700;
                font-size: 28px;
                line-height: 37px;
                color: #072136;
                margin-bottom: 30px;
            }

            .rfp__detail__body__content__actions {
                display: flex;
                margin-bottom: 40px;
                gap: 10px;

                .btn2 {
                    padding: 10px 20px;
                    background: #072136;
                    border: 3px solid #072136;
                    border-radius: 30px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;

                    &-outline {
                        background: #ffffff;
                        border: 3px solid #415473;
                        color: #415473;
                    }
                }

                .btn2-empty {
                    background: white;
                    border: 0;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #415473;
                }
            }

            .rfp__body__text {
                font-size: 16px;
                line-height: 24px;
                color: #072136;
            }

            .rfp-file-table {

                td,
                th {
                    padding: 12px 16px;
                }
                span {
                    color: #415473; /* Set the color to blue to mimic the default link color */
                    text-decoration: underline; /* Underline the text to mimic link underlining */
                    cursor: pointer; /* Change cursor to pointer on hover to indicate interactivity */
                    &:hover{
                        color: rgb(70, 70, 131); /* Change color on hover to provide visual feedback */
                    }
                }
            }
        }
    }
}

.modal__applied {
    &__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: #072136;
        margin-bottom: 10px;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #072136;
        margin-bottom: 40px;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}

a.btn {
    color: #fff;
    text-decoration: none;
}

@media (max-width: 991px) {
    .rfp__detail {
        padding: 35px 20px 0;

        .rfp__detail__body {
            flex-direction: column-reverse;
            gap: 0;

            &__aside {
                width: 100%;
                margin-bottom: 30px;
            }

            .rfp__detail__body__content {
                margin: 0;

                .rfp__detail__body__content__header {
                    svg {
                        margin-left: 0;
                    }
                }

                .rfp__detail__body__content__actions {
                    flex-direction: column;

                    .btn2 {
                        padding: 8px 16px;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                .rfp-file-table {

                    th,
                    td {
                        padding: 12px 4px;
                    }
                }
            }
        }
    }
}

.btn5 {
    padding: 10px 40px;
    background: #072136;
    border: 3px solid #072136;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    &-outline {
        background: #ffffff;
        border: 3px solid #415473;
        color: #415473;
    }
}
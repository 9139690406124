.Input {
  color: #415473;

  &__input {
    background-color: transparent;
    border: 1px solid #dadbe2;
    border-radius: 10px;
    padding: 15px;
    // max-width: 484px;
    color: #072136;
    outline: none;
    transition: border-color 0.25s;

    &--error {
      border: 1px solid #ee465a;
    }
  }

  &__error {
    font-size: 14px;
    line-height: 21px;
    color: #ee465a;
  }

  &__label {
    font-family: 'Poppins';
  }

  input:focus {
    border: 1px solid #5eb5ca;
  }
}
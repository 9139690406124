.svg-container div {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.svg-container div.active {
    opacity: 1;
}

.svg-container div.inactive {
    opacity: 0.5;
}

.svg-icon{
    width: 85%;
    height: 25%;
}

.AnimText div{
    opacity: 1;
    color: white;
    font-family: 'Poppins';
    margin: 0;
    text-align: 'center';
}

.AnimText {
    text-align: center;
    margin-top: 10px; /* Adjust as needed */
}


.orange-dot{
    color: #FF8533;
}

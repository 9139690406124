.MultiSelectDropdown {
    width: 100%;

    .naics-wrapper {
        flex-wrap: wrap;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 5px;

        .naics-item {
            align-items: center;
            background-color: #F4F5F8;
            padding: 5px 15px 5px 15px;
            margin-right: 10px;
            border-radius: 10px;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            color: #415473;
            cursor: pointer;
        }
    }
    .index-number {
        text-align: right;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: right;
        margin-top: 50px;
        margin-bottom: 10px;
        color: #9497ab;
    }

    .MultiSelectDropdown__input {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: #415473;
        font-size: 16px;
        line-height: 24px;
        border-radius: 10px;
        padding: 15px 0px;

        .titleContainer {
            display: flex;
            flex-direction: column;
            margin: 0;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

            .filter-title-modified {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                color: #415473;
                margin: 0;
                margin-right: 20px;
            }

            .filter-subtitle1 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 27px;
                color: #9A97AB;
            }
        }
    }

    .collapse-menu {
        display: none;
        width: 100%;
        border-top: none;
        border-radius: 0px 0px 10px 10px;

        .clearSelect {
            margin-top: 10px;
            width: 100px;
            height: 21px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;

            text-align: left;
            text-decoration-line: underline;
            color: #415473;
            cursor: pointer;
        }

        .dropdown-search {
            position: relative;
            width: auto;
            background: #ffffff;
            border: 1px solid #dadbe2;
            border-radius: 70px;
            text-align: left;
            margin: 10px 0;

            input {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                padding: 11px 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #415473;
                outline: 0;
            }

            svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: #415473;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            max-height: 300px;
            overflow-y: auto;

            li {
                padding: 10px 10px 10px 0;
                display: flex;
                justify-content: space-between;
                color: #9497ab;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #dadbe2;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #415473;
                border-radius: 3px;
            }
        }
    }

    .show-all {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        text-decoration-line: underline;
        color: #9497ab;
        cursor: pointer;
    }

    &.active {
        .MultiSelectDropdown__input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-color: #9497ab;
        }

        .collapse-menu {
            display: block;
        }
    }

    .source-request-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0px;

        .source-content {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #9497ab;
            flex: none;
            align-self: stretch;
            margin: 0;
        }

        .request-button {
            padding: 0 20px;

            background: transparent;
            color: #415473;
            margin-top: 20px;
            min-width: 50px;
            height: 44px;
            border: 3px solid #415473;
            border-radius: 10px;
            align-self: center;

            &::first-line {
                margin: 0;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 991px) {
    .MultiSelectDropdown {
        .index-number {
            margin-top: 24px;
        }
    }
}
.Header {
    background-color: #072136;
    color: #fff;
    height: 83px;
    position: relative;

    .row {
        margin: 0 -25px;
    }

    .col {
        padding: 0 25px;
    }

    a {
        white-space: nowrap;

        &:hover {
            color: darken(#fff, 5);
        }
    }
}

.Trial {
    background-color: #5C89D1;
    color: #fff;
    height: 83px;
    .row {
      margin: 0 -25px;
    }
    .col {
      padding: 0 25px;
    }
  
    &__container {
      padding: 0px 37px 0px 300px;
    }
  
    button {
      background-color: #5C89D1;
      color: #fff;
      outline: #fff;
      border: 2px solid #fff;
      padding: 5px 20px 5px 20px;
      border-radius: 20px;
      height: 40px;
    }
    a {
      &:hover {
        color: darken(#FF6432, 5);
      }
    }
  }

@media (max-width: 991px) {
    .mobile-menu-toggler {
        width: 25px;
        fill: #fff;
        cursor: pointer;
    }

    .mobile-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 !important;
        background: #072136;
        padding-bottom: 12px;
        z-index: 1;

        &.open {
            display: initial;
        }

        .col-12 a {
            padding: 6px 12px;
            display: inline-block;
        }
    }
}

@media (min-width: 992px) {
    .mobile-menu-toggler {
        display: none;
    }
}
.dashboard {
    height: 100%;
    width: 100%;
    background-color: #f4f5f8;


    .dashboard__header {
        .dashboard__header__title {
            font-weight: 700;
            font-size: 34px;
            line-height: 44px;
            color: #072136;
            margin-bottom: 16px;
        }

        .dashboard__header__tools {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 74px;
            padding: 12px 0 34px 0;


            .collapse-menu {
                    display: block;
                    width: 80%;
                    height: 60px;
                    border-top: none;
                    border-radius: 0px 0px 10px 10px;
                    margin-top: 30px;
                    margin-bottom: 30px;

                    .naics-wrapper {
                        flex-wrap: wrap;
                        padding-top: 20px;
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
                        grid-gap: 5px;
                
                    .naics-item {
                            align-items: center;
                            background-color: #DADBE2;
                            padding: 5px 15px 5px 15px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            border-radius: 10px;
                            font-family: 'Poppins';
                            font-size: 14px;
                            font-weight: 400;
                            color: #415473;
                            cursor: pointer;
                        }
                    }
                
                    .clearSelect {
                        margin-top: 2px;
                        margin-left: 15px;
                        width: 100px;
                        height: 10px;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 21px;
            
                        text-align: left;
                        text-decoration-line: underline;
                        color: #415473;
                        cursor: pointer;
                    }
            
                    .dropdown-search {
                        position: relative;
                        display: flex;
                        width: auto;
                        height: 100%;
                        background: #ffffff;
                        border: 1px solid #dadbe2;
                        border-radius: 70px;
                        text-align: left;
                        margin: 10px 0;
            
                        input {
                            width: 100%;
                            flex: 1;
                            height: 70px;
                            border: none;
                            background: transparent;
                            padding: 11px 20px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #415473;
                            outline: 0;
                        }
            
                        svg {
                            position: absolute;
                            right: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            color: #415473;
                        }
                        button {
                            padding: 11px 20px;
                            border: none;
                            border-radius: 70px;
                            cursor: pointer;
                            height:60px; /* Same height as input for consistency */
                        }
                    }
            
                    ul {
                        list-style: none;
                        padding: 0;
                        max-height: 300px;
                        overflow-y: auto;
            
                        li {
                            padding: 10px 10px 10px 0;
                            display: flex;
                            justify-content: space-between;
                            color: #9497ab;
                        }
            
                        &::-webkit-scrollbar {
                            width: 5px;
                            background-color: #dadbe2;
                        }
            
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            border-radius: 3px;
                        }
            
                        &::-webkit-scrollbar-thumb {
                            background-color: #415473;
                            border-radius: 3px;
                        }
                    }
                }
            
                .show-all {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
            
                    text-decoration-line: underline;
                    color: #9497ab;
                    cursor: pointer;
                }
            
            }


    .dashboard_filter_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 60px;
            border: 1px solid #415473;
            border-radius: 10px;
            cursor: pointer;
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 400;
            color: #415473;
            margin-top: 20px;
        }
    }

    .dashboard_title_one {
        font-family: 'Poppins';
        font-size: 28px;
        font-weight: 700;
        color: #415473;
        margin-top: -40px;
    }

    .dashboard_title_two {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 400;
        color: #415473;
        margin-bottom: 30px;
    }

    .filter_clear {
        cursor: pointer;
        text-decoration: underline;
    }

    .dashboard_title_three {
        font-family: 'Poppins';
        font-size: 28px;
        font-weight: 700;
        color: #415473;
        margin-bottom: 30px;
    }

    .dashboard_no_result {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-family: 'Poppins';
        font-size: 30px;
        font-weight: 700;
        color: #415473;
    }

    .dashboard__filter {
        width: 30%;
        max-width: 300px;
        flex-shrink: 0;

        .dashboard__filter__box {
            padding: 20px 0px;
        }

        #filter-title-box {
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;
            justify-content: space-between;

            .filter__title {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 22px;
                line-height: 28px;
                color: #415473;
            }

            #clearAll-Span {
                font-family: "Poppins";
                font-family: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                text-decoration-line: underline;
                color: #415473;
                opacity: 0.5;
            }
        }

        #filter-main-box {
            .filter-left {
                background-color: #072136;
            }

            .filter-right {
                background-color: #f37845;
            }
        }

        .filter__subtitle {
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            color: #415473;
        }

        .filter-list {
            list-style: none;
            padding: 0;

            .name-count-container {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                .count {
                    color: #9497ab;
                }
            }
        }

        .filter-collapse {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            color: #415473;
            margin-top: 40px;
            text-align: center;
            cursor: pointer;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px 0px;
            gap: 10px;
            margin-top: 20px;
            height: 100.67px;

            .edit-button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 1em 2em;
                gap: 10px;
                font-size: 12px;
                width: 234px;
                height: 44px;
                background: #415473;
                border-radius: 10px;
            }
        }
    }

    .dashboard__content {
        flex-grow: 1;
        // margin-left: 65px;

        .post-box {
            position: relative;
            padding: 20px 25px 30px;
            margin-bottom: 20px;
            background: #ffffff;
            border-radius: 20px;
            transition-duration: 0.2s;
            word-break: break-word;

            &.active {
                &:hover {
                    cursor: pointer;
                    box-shadow: 0px 0px 28px rgba(65, 84, 115, 0.1);
                }
            }

            .post-box__header {
                display: flex;
                font-weight: 700;
                justify-content: space-between;
                align-items: center;

                svg {
                    color: #dadbe2;
                    margin-left: 20px;

                    &.active {
                        color: #f37845;
                    }

                    &.active_favorite {
                        color: #5c89d1;
                    }

                    &.active_hide {
                        color: #415473
                    }
                }
            }

            .post-box__applied {
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                background-color: #f37845;
                border-radius: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 1;
                color: white;
                padding: 13px;
                // margin-top: 10px;

                svg {
                    margin-top: -3px;
                    margin-left: 10px;
                    height: 12px;
                    // width: 20px;
                }
            }

            .post-box__favorite {
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                background-color: #5c89d1;
                border-radius: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 1;
                color: white;
                padding: 13px;
                // margin-top: 10px;

                svg {
                    margin-top: -3px;
                    margin-left: 10px;
                    height: 12px;
                    // width: 20px;
                }
            }

            .post-box__hide {
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                background-color: #415473;
                border-radius: 10px;
                font-weight: 700;
                font-size: 16px;
                line-height: 1;
                color: white;
                padding: 13px;
                // margin-top: 10px;

                svg {
                    margin-top: -3px;
                    margin-left: 10px;
                    height: 12px;
                    // width: 20px;
                }
            }

            .post-box__title {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 22px;
                line-height: 28px;
                color: #072136;
                margin-top: 10px;
            }

            .post-box_detail {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

            .post-box__subtitle {
                width: 450px;
                align-items: center;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #415473;
                // margin-top: 10px;
                margin-right: 50px;

                svg {
                    width: 25px;
                }
            }

            .post-box-wrapper {
                display: flex;
                align-items: center;
            }

            .post-box__score {
                padding: 10px 10px;
                background-color: #f4f5f8;
                border-radius: 10px;
                font-weight: 700;
                font-size: 16px;
                // line-height: 24px;
                color: #415473;
                // margin-top: 25px;
            }

            .post-box__hidden-modal {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,0.5);

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 30px;
                    background-color: white;
                    border-radius: 20px;
                    box-shadow: 0 0 24px rgba(0,0,0,0.2);

                    p {
                        font-weight: bold;
                        margin: 0;
                    }
                    svg {
                        right: 20px;
                        margin-left: 10px;
                        font-size: 5px; /* or any other size you prefer */
                        cursor: pointer;
                    }
                    button {
                        margin-left: 20px;
                        padding: 10px 30px;
                        border: none;
                        outline: none;
                        border-radius: 99px;
                        border: 1px solid black;
                        background-color: white;
                    }
                }
            }

            #see-more-button {
                opacity: 0;
                transition: all 0.5s ease;
            }

            &:hover {
                #see-more-button {
                    opacity: 1;
                }
            }
        }
    }
}

.react-pagination {
    display: flex;
    gap: 15px;
    list-style: none;
    justify-content: center;

    .next {
        a {
            display: inline-block;
            border-radius: 5px;
            line-height: 40px;
            min-width: 40px;
            text-align: center;
            border: 1px solid #dadbe2;
            background-color: white;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 700;
            color: #9497ab !important;
            text-decoration: none;
        }

        &.selected,
        &:hover:not(.disabled) {
            a {
                color: #fff !important;
                background: #5c89d1;
            }
        }

        &.disabled a {
            opacity: 0.7;
            cursor: alias;
        }
    }

    .disable {
        a {
            display: inline-block;
            border-radius: 5px;
            line-height: 40px;
            min-width: 40px;
            text-align: center;
            border: 1px solid #dadbe2;
            background-color: #dadbe2;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 700;
            color: white !important;
            text-decoration: none;
        }

        &.selected,
        &:hover:not(.disabled) {
            a {
                color: white !important;
                background-color: #dadbe2;
            }
        }
    }

    li {
        padding: 0;

        a {
            display: inline-block;
            border-radius: 5px;
            line-height: 40px;
            min-width: 40px;
            text-align: center;
            // border: 1px solid #415473;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 700;
            color: #9497ab !important;
        }

        &.selected,
        &:hover:not(.disabled) {
            a {
                color: #fff !important;
                background: #5c89d1;
            }
        }

        &.disabled a {
            opacity: 0.7;
            cursor: alias;
        }
    }
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}


.filter-wrapper {
    .filter-title {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        justify-content: space-between;

        .title {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: #415473;
        }
    }

    .filter-main {
        margin-top: 20px;

        .filter-left {
            .filter-option {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding: 10px;
                border-radius: 10px;
                margin-bottom: 5px;
                cursor: pointer;

                .filter-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                }
            }

            .filter-option:hover {
                background-color: #f4f5f8;
            }

            .active {
                background-color: #f4f5f8;
            }
        }
    }

    .filter-item-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-left: 0 !important;

        .filter-item {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            color: #9497AB;
        }

        .count {
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 400;
            color: #9497AB;
        }

        li:first-child {
            list-style-type: none;
        }
    }

    .filter-list-container {
        padding: 20px 0;
    }

    .clearSelect {
        width: 126px;
        height: 21px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        margin-right: 10px;
        text-align: right;
        text-decoration-line: underline;
        color: #415473;
        cursor: pointer;
    }
    
    .rfpRequestContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 40px 0px;

        .request-source-text {
            width: 100%;
            min-height: 72px;
            overflow-y: auto;

            font-family: "Poppins";
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #9497ab;
        }

        .request-source-button {
            margin-top: 30px;
            padding: 15px 20px;
            background: transparent;
            color: #415473;
            display: flex;
            align-items: center;

            height: 44px;
            border: 2px solid #415473;
            border-radius: 30px;
            align-self: center;
        }
    }
}

.filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .clear-button {
        margin-left: 30px;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 500;
        color: #415473;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (max-width: 991px) {
    .dashboard {
        .dashboard__header {
            .dashboard__header__title {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 10px;
            }

            .dashboard__header__tools {
                margin-bottom: 40px;
                flex-direction: column;

                .dashboard-search {
                    width: 100%;
                    max-width: initial;
                    margin-bottom: 15px;
                }

                .dashboard-sorting {
                    justify-content: space-between;
                }
            }
        }

        .dashboard__filter {
            width: 100%;
            max-width: 100%;
            margin-bottom: 40px;

            .dashboard__filter__box {
                padding: 20px 0px;
            }

            #filter-title-box {
                padding-bottom: 20px;
            }

            .filter__subtitle {
                font-size: 16px;
                line-height: 24px;
            }

            .filter-list {
                .name-count-container {
                    margin-top: 10px;
                }
            }

            .filter-collapse {
                margin-top: 20px;
            }

            .button-container {
                .edit-button {
                    width: 100%;
                }
            }

            .rfpRequestContainer {
                padding: 20px 0px;

                .request-source-text {
                    min-height: 50px;
                }

                .request-source-button {
                    margin-top: 20px;
                }
            }
        }

        .dashboard__content {
            margin-left: 0px;

            .post-box {
                padding: 20px 25px 30px;
                margin-bottom: 20px;

                .post-box__header {
                    svg {
                        margin-left: 10px;
                    }
                }

                .post-box__title {
                    font-size: 18px;
                    line-height: 24px;
                }

                .post-box__subtitle {
                    font-size: 14px;
                    line-height: 24px;
                }

                .post-box__score {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}
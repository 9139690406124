.Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #072136;
    padding: 50px;

    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            margin-top: 8px;
            font-family: 'Poppins';
            font-size: 14px;
            color: white;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__term {
        // margin-top: -14px;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 700;
        color: white;

        a {
            cursor: pointer;
            transition: text-shadow 0.3s ease; /* Smooth transition effect */

            /* Define the styles for the hover state */
            &:hover {
              text-shadow: 0 0 10px white; /* Add a red glowing effect when hovered over */
            }
        }
    }

    &__text {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        color: white;
    }

    .btn-subscribe {
        box-shadow: 0 0;
        border: none;
        border-left: 1px solid white;
    }

    .subscribe-form {
        margin-top: 20px;
    }

    .subscribe-label {
        padding-left: 10px;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
    }

    .form-group {
        min-width: 420px;
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 400;
    }
}


@media screen and (max-width: 991px) {
    .Footer {
        display: flex;
        flex-direction: column;
        background-color: #072136;
        padding: 20px 10px;
        min-height: auto;
        width: 100%;
        overflow-x: hidden; /* Hide horizontal overflow */

        &__left,
        &__right {
            align-items: center; /* Center align items */
            margin-top: 10px; /* Add some top margin */
            text-align: center; /* Center align text */
            width: 100%;
        }

        // &__right {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-start;
        //     margin-top: 20px;
        //     margin-right: 30px;
        // }

        .form-group {
            min-width: 200px;
        }
    }
}
.Setup {
  &__title {
    display: flex;
    width: 100%;
    padding-left: 50px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 700;
  }

  &__navigate {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 50px;
  }

  &__active {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
    color: #5C89D1 !important;
    cursor: pointer;
    text-decoration: none;
  }

  &__inactive {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
    color: #DADBE2 !important;
    cursor: pointer;
    text-decoration: none;
  }

  &__line {
    margin: 10px 20px;
    width: 90%;
    height: 1px;
    background-color: #DADBE2;
  }

  &__card {
    padding: 50px 16px 70px;
    margin-bottom: 100px !important;
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 80%;
    text-align: center;
    color: #415473;
  }

  .rfp-settings-edit-info-category {
    display: flex;
    flex-direction: column;
    width: 80%;
    border-bottom: 1px solid #dadbe2;
    padding-bottom: 30px;
    margin-bottom: 60px;

    .request-source-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9497ab;
    }

    .requestSourceButton {
      background-color: white;
      color: #415473;
      border: 3px solid #415473;
      align-self: center;
    }
  }

  .button-container {
    display: flex;

    justify-content: space-between;
    min-width: 40%;

    .back-button {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 700;
      background-color: white;
      color: #415473;
      border: none;

      &::before {
        content: "<";
        display: inline-block;
        margin-right: 5px;
      }
    }
  
    .skip-button {
      padding: 10px 40px;
      background-color: #dadbe2;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 700;
      color: white;
      border: none;

      &::after {
        content: ">>";
        display: inline-block;
        margin-left: 5px;
      }
    }

    .skip-button:hover {
      background-color: #c6630dde;
    }

    .continue-button {
      padding: 10px 40px;
      background-color: #5C89D1;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 700;
      color: white;
      border: none;

      &::after {
        content: ">";
        display: inline-block;
        margin-left: 5px;
      }
    }

    

    .continue-button:hover {
      background-color: #5c89d1df;
    }
  
    .continue-button:disabled {
      background-color: #dadbe2;
    }
  
  }

  .Preference-skip-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;

    color: #9497ab;
    margin-top: 30px;
  }
}

.request-button {
  margin-top: 50px;
}

.sourceInput {
  border: 1px solid #dadbe2;
  border-radius: 10px;
}

@media (max-width: 991px) {
  .Setup {
    &__card {
      padding: 50px 16px 50px;
      margin-bottom: 40px !important;
    }

    .rfp-settings-edit-info-category {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}
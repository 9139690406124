.SignUp {
  &__card {
    padding: 50px 16px 50px;
    margin-bottom: 50px !important;
  }

  .bottom-line {
    margin-top: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #415473;
  }

  .btn_blue {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 157px;
    // height: 50px;
    border-radius: 40px;
    padding: 15px 30px 15px 30px;
    background-color: #5C89D1;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 700;
  }

  .btn_blue:hover {
    background-color: #5c89d1e0;
  }
}

.signup-anim-container {
  display: flex;
.anim-left {
    flex: 1;
    background-color: #031422;
    height: 100%;
    min-height: 1150px;
    min-width: 300px;
    width: 50%;
    margin-top: -5%;
    }
    
    .anim-right {
    flex: 1;
    height: 100%;
    min-height: 1120px;
    min-width: 300px;
    width: 50%;
    }
}

.Header {
  background-color: #072136;
  color: #fff;
  height: 83px;
  position: relative;
  padding: 0 50px;

  .row {
      margin: 0 -25px;
  }

  .col {
      padding: 0 25px;
  }

  a {
      white-space: nowrap;

      &:hover {
          color: darken(#fff, 5);
      }
  }
  &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
          margin-top: 8px;
          font-family: 'Poppins';
          font-size: 14px;
          color: white;
      }
  }

}

@media (max-width: 991px) {
  .SignUp {
    &__card {
      margin-bottom: 70px !important;
    }
  }
  .signup-anim-container {
    display: flex;
    width: 100vw;
  .anim-left {
      flex: 1;
      background-color: #031422;
      height: 100%;
      width: 50vw;
      min-width: 50%;
      margin-top: -5%;
      min-height: auto;
      height: auto;
      }
      
      .anim-right {
      flex: 1;
      height: auto;
      min-width: 50%;
      width: 50vw;
      min-height: auto;
      height: auto;
      }
  }
}
.Checkbox {
  $base: &;

  &__label {
    user-select: none;
    color: #415473;
  }

  &__input {
    flex-shrink: 0;
    margin-right: 10px;
    border: 1px solid #cbd5e0;
    background: #ffffff;
    width: 17px;
    height: 17px;

    &:focus {
      outline: none;
    }
  }

  &__input--checkbox {
    border: 1px solid #cbd5e0;
    border-radius: 4px;
    display: flex;

    svg {
      width: 7px;
      margin: auto;

      &.icon-indeterminate path {
        stroke: #415473;
      }
    }
  }

  &__container {
    align-items: start;
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    input:focus~#{$base}__input--checkbox {
      border: 1px solid #415473;
    }

    input:focus~#{$base}__input--radio {
      border: 1px solid #415473;
    }

    input:disabled~#{$base}__input--checkbox,
    input:disabled~#{$base}__input--radio {
      border: 1px solid #cbd5e0;
    }

    input:disabled~#{$base}__label {
      color: #929f9a;
    }

    input:checked~#{$base}__label {
      color: var(--bs-dark);
    }

    input:checked~#{$base}__input--checkbox {

      // border: 1px solid #415473;
      svg {
        path {
          fill: #415473;
        }
      }
    }

    input:disabled~#{$base}__input--checkbox {
      background: #929f9a;
      border: 1px solid #929f9a;
    }

    input:checked~#{$base}__input--radio {
      position: relative;
      border: 1px solid #415473;
      background-color: #415473;
    }

    input:checked~#{$base}__input--radio:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  &__error {
    #{$base}__input {
      border: 1px solid #ff3d3d;
    }
  }

  &__input--radio {
    border-radius: 50%;
  }

  .label__badge {
    border: 2px solid #415473;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #415473;
  }

  &.primary {
    input:checked~#{$base}__input--checkbox {
      border: 1px solid #1f437a;
      background-color: #5c8dd6;
    }

    .Checkbox__input--checkbox {
      svg {
        path {
          stroke: #fff !important;
          fill: #fff !important;
        }
      }
    }
  }
}
@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";

:root {
    --bs-orange: #FF8533;
}

body {
    font-family: "Poppins", sans-serif;
}

a {
    color: #415473;

    &:hover {
        color: #072136;
    }

    &.link-white {
        color: #fff;
    }
}

.bg-theme {
    background-color: #f4f5f8;
}

.btn {
    background-color: var(--bs-orange);
    padding: 14px 20px;
    color: #fff;
    transition: background-color 0.25s;
    font-weight: 700;
    border-radius: 50px;
    border: none;
    outline: none;

    &:hover {
        background-color: #ff9265;
    }

    &:focus {
        background-color: #db551e;
    }

    &:disabled {
        background-color: #dadbe2;
    }

    &--primary {
        background-color: #415473;

        &:hover {
            background-color: #072136;
        }

        &:focus {
            background-color: #415473;
            outline: none;
        }

        &:disabled {
            background-color: #dadbe2;
        }
    }

    &--outline {
        padding: 11px 20px;
        border: 3px solid #415473;
        border-radius: 40px;
        background-color: transparent;
        color: #415473;
        transition: border-color 0.25s color 0.25s;

        &:hover {
            background-color: #415473;
            color: #fff;
        }

        &:focus {
            background-color: #072136;
            border-color: #072136;
            color: #fff;
        }

        &:disabled {
            color: #dadbe2;
            border: 3px solid #dadbe2;
            background-color: transparent;
        }
    }
}

.border-bottom {
    border-bottom: 1px solid #dadbe2;
}

.font-weight-normal {
    font-family: 'Poppins';
    font-weight: 400 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.w-135 {
    width: 135px !important;
}

/* Responsive breakpoints for container width */
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }

    .w-lg-150 {
        width: 150px !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 991px) {
    .btn {
        padding: 11px 18px;

        &--outline {
            padding: 9px 18px;
        }
    }
}
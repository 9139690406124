.news-list-container {

    .list-card {
        background: #ffffff;
        border-radius: 30px;
        font-size: 16px;
        line-height: 24px;
        color: #072136;

        .list-card__title {
            font-weight: 700;
            font-size: 28px;
            line-height: 37px;
            color: #072136;
            margin: 0;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 991px) {
    .list-card {
        .list-card__title {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
        }
    }
}
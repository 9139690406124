.Login {
    background-color: #f4f5f8;
    padding-top: 50px;

    &__card {
        margin-top: 20px;
        background: #ffffff;
        border-radius: 30px;
        // padding: 37px 16px;
        height: 100%;
    }

    &__title {
        font-family: 'Poppins';
        font-size: 32px;
        line-height: 48px;
        color: #072136;
    }

    &__subtitle {
        color: #415473;
    }

    &__form {
        max-width: 484px;

        .button-container {
            margin-top: 60px;
        }
    }

    &__btn {
        max-width: 150px;
        padding: 15px 30px 15px 30px;
        margin-bottom: 30px;
        font-family: 'Poppins';
    }
    
    &__link {
        cursor: pointer;
    }

    &__btn1 {
        max-width: 200px;
        padding: 15px 30px 15px 30px;
        margin-bottom: 30px;
        font-family: 'Poppins';
    }

    &__newUser {
        font-family: 'Poppins';
        color: #415473;
    }
    
}

.login-anim-container {
    display: flex;
    .anim-left {
        flex: 1;
        background-color: #031422;
        height: 100%;
        min-height: 1150px;
        min-width: 300px;
        width: 50%;
        margin-top: -10%;
        }
        
    .anim-right {
        flex: 1;
        height: 100%;
        min-height: 1120px;
        min-width: 300px;
        width: 50%;
        margin-top: -10%;
        }

}
    


.Header {
    background-color: #072136;
    color: #fff;
    height: 83px;
    position: relative;
    padding: 0 50px;

    .row {
        margin: 0 -25px;
    }

    .col {
        padding: 0 25px;
    }

    a {
        white-space: nowrap;

        &:hover {
            color: darken(#fff, 5);
        }
    }
    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            margin-top: 8px;
            font-family: 'Poppins';
            font-size: 14px;
            color: white;
        }
    }

}

@media (max-width: 991px) {
    .Login {
        &__card {
            margin-bottom: 70px !important;
        }

        &__title {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .login-anim-container {
        display: flex;
        width: 100vw;
        min-height: 300px;
      .anim-left {
          flex: 1;
          background-color: #031422;
          height: 100%;
          width: 50vw;
          min-width: 50%;
          margin-top: -5%;
          height: auto;
          }
          
          .anim-right {
          flex: 1;
        //   height: auto;
          min-width: 50%;
          width: 50vw;
        //   height: auto;
          }
      }
}
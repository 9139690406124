.RatingBadge {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 21px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    margin-right: 10px;
}